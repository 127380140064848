// Variable overrides

 $theme-colors: (
  "primary":#0b6478,
  "success": #519969,
  "warning": #fbf07f42,
  "danger": #db1616,
  "header": #e6e6e6,
  "info": #1c548c,
  "tempo": #095064
);

:root {
  --font-size: 13px;
}
