body {
  font-size: var(--font-size);
}

.form-control {
  font-size: var(--font-size);
}

.form-group {
  margin-bottom: 5px;
}
// Here you can add other styles
// Añadir "*" a los campos obligatorios
.form-group.required > label:after {
	font-weight: normal;
	font-size: 14px;
	content: "*";
	margin-left: 2px;
	color: $danger;
}


.toast-warning {
  color:  theme-color($key: "primary") !important;
}


// Here you can add other styles
// Añadir "*" a los campos obligatorios
.form-row.required > label:after {
	font-weight: normal;
	font-size: 14px;
	content: "*";
	margin-left: 2px;
	color: $danger;
}

.select2-container--default .select2-results>.select2-results__options {
    background-color: theme-color($key: "primary");
    color: theme-color($key: "light");
}

.select2-results__option--highlighted,
.select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  background-color: theme-color($key: "primary");
  color: theme-color($key: "light");
}
/*
*  DATATABLE
*/

table.dataTable td {
  font-size: 1em;
}

table.dataTable tr.dtrg-level-0 td {
  font-size: 1.1em;
}

.dataTables_scrollBody {
  background-color: #fff !important;
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #0b647824 !important;
}

table.dataTable tbody tr.selected,
table.dataTable tbody th.selected,
table.dataTable tbody td.selected {
  color: black !important;
}

.dataTables_wrapper .dataTables_processing {
  //background-color: var(--primary);
  top: 50% !important;
  color: var(--primary);
  }


table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color:#fbf07f42 !important;
}

table .dte-inlineAdd  {
  background-color:#fbf07f42 !important;
}

td.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
}

/*
* EDITOR DATATABLE
*/
div.DTE div.DTE_Form_Error {
  color: var(--danger);
}

div.DTE_Inline div.DTE_Field input[type=color],
div.DTE_Inline div.DTE_Field input[type=date],
div.DTE_Inline div.DTE_Field input[type=datetime],
div.DTE_Inline div.DTE_Field input[type=datetime-local],
div.DTE_Inline div.DTE_Field input[type=email],
div.DTE_Inline div.DTE_Field input[type=month],
div.DTE_Inline div.DTE_Field input[type=number],
div.DTE_Inline div.DTE_Field input[type=password],
div.DTE_Inline div.DTE_Field input[type=search],
div.DTE_Inline div.DTE_Field input[type=tel],
div.DTE_Inline div.DTE_Field input[type=text],
div.DTE_Inline div.DTE_Field input[type=time],
div.DTE_Inline div.DTE_Field input[type=url],
div.DTE_Inline div.DTE_Field input[type=week] {
  margin: 0px  !important;
}

div.DTE_Field_Type_select select {
  width: auto !important;
}

div.DTE_Inline div.DTE_Field input.form-control {
  height: auto  !important;
}

div.DTE_Inline div.DTE_Field input {
  margin: 0px  !important;
}


/*
* TINYMCE
*/
.tox-statusbar__branding {
  display: none !important;
}

.tox:not([dir=rtl]) {
  border: none !important;
}

.tox .tox-statusbar {
  display: none !important;
}


/*
* air-datapicker
*/
.datepicker {
  z-index: 2000 !important;
}

/*  jsTree */

.jstree-proton > .jstree-container-ul > .jstree-node {
  margin-left: -7px;
  margin-right: 4px;
}

.jstree-proton .jstree-node {
  margin-left: 16px;  // 22px;
}

/*
.jstree-proton .jstree-anchor {
  line-height: 22px !important;
  margin: 0px !important;
  height: 22px !important;
}
*/


.c-multi-select-inline .c-multi-select-options {
    overflow-y: scroll;
    height: 300px;
}
